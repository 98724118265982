import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import imgDefault from '../../assets/image/placeholder.png'
import { VscDebugBreakpointData } from 'react-icons/vsc';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Block_10 = ({ title, text, listsAbout, listsServices, image, corte, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (

        <section className="w-full flex justify-center md:py-32 py-10 relative">
            <div className="max-w-6xl md:flex md:p-0 px-2 content-reverse flex-row-reverse">
                <div className="md:w-[50%]">
                    <div className="w-full h-full block">
                        <div className="w-full h-[360px] lg:h-[70%] bg-cover bg-center rounded-2xl"
                            style={{ backgroundImage: `url("${image ? image : imgDefault}")` }}>
                        </div>
                        <div className=" hidden lg:block w-full h-[30%] mt-2">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                spaceBetween={10}
                                slidesPerView={3}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },
                                    480: {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },
                                    640: {
                                        slidesPerView: 3,
                                        spaceBetween: 10
                                    }
                                }}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                navigation
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                {
                                    corte?.slice(3, 16).map((item, index) => {
                                        return (
                                            <SwiperSlide key={index} className=" w-[80px] lg:w-[150px]  h-[80px] lg:h-[180px] bg-center bg-cover rounded-2xl" style={{ backgroundImage: `url("${item}")` }} ></SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="md:w-[50%] py-4 md:px-6 px-3 self-center md:text-start text-center">
                    {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed.length > 1 ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                            :
                            <h2 className='pb-3 capitalize'>
                                {
                                    title ? title :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h2>
                    }
                    <p className='pb-3'>
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>
                    <div className="flex md:flex-row  py-3">
                        <div className="md:w-[90%] w-full">
                            {
                                listsAbout ?
                                    <ul className='grid pb-5'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                listsServices ?
                                    <ul className="grid md:grid-cols-2 grid-cols-1 place-items-start md:place-items-start pl-[50px] pb-5">
                                        {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }
                        </div>
                    </div>
                    <div>
                        <ButtonContent />
                    </div>

                </div>
            </div>

            <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-03.png?alt=media&token=6edc149e-aec1-40e0-bbad-fe6295fcd347"
                alt="pencil"
                className="absolute md:top-0 top-[25%] left-0 md:w-[200px] w-[80px] h-auto rotate-12"
            />


            <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-02.png?alt=media&token=7045b644-7b19-4a91-9a61-18de23616fd3"
                alt="pencil"
                className="absolute md:top-0 top-[25%] right-0 md:w-[200px] w-[80px] h-auto -rotate-3"
            />

            <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-05.png?alt=media&token=c8ee951f-32e6-4bc3-81f3-7128e5dd3976"
                alt="pencil"
                className="absolute md:bottom-0 bottom-[5%] right-0 md:w-[200px] w-[80px] h-auto -rotate-12"
            />

            <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-06.png?alt=media&token=1edadcdd-9c5e-4c51-9916-7281649d3744"
                alt="pencil"
                className="absolute md:block  md:bottom-0 bottom-[5%] left-0 md:w-[200px] w-[80px] h-auto rotate-45 "
            />

        </section>

    );

}

export default Block_10;