import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import imgDefault from '../../assets/image/placeholder.png'
import { FaHandshake } from "react-icons/fa";
import { MdOutlineVisibility } from "react-icons/md";
import { FiTarget } from "react-icons/fi";


function Values_2({ image1 }) {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description?.substring(0, 305),
      icon: (
        <FaHandshake fontSize={70} className="bg-2 text-white rounded-full p-4" />
      ),
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description?.substring(0, 279),
      icon: (
        <MdOutlineVisibility
          fontSize={70}
          className="bg-white color-2 rounded-full p-4 border-[2px] border-gray-500"
        />
      ),
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description?.substring(0, 280),
      icon: (
        <FiTarget
          fontSize={70}
          className="bg-2 text-white rounded-full p-4"
        />
      ),
    },
  ];

  return (
    <div className="my-5 relative">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-07.png?alt=media&token=e96e9400-6122-42cf-ad1a-93aaade253f9"
        alt="pencil"
        className="absolute md:top-0 top-[8%] md:left-0 left-10 md:w-[200px] w-[100px] h-auto rotate-12"
      />

      <img
        src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-08.png?alt=media&token=6f785933-fb01-483e-854f-22922c14d53e"
        alt="pencil"
        className="absolute md:top-0 top-[8%] md:right-0 right-10 md:w-[200px] w-[100px] h-auto rotate-12"
      />

      <img
        src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F9298232-05.png?alt=media&token=4ea1ea7f-5c80-4b70-aa8e-090bdf23addc"
        alt="pencil"
        className="absolute md:bottom-0 -bottom-[2%] right-0 md:w-[200px] w-[80px] h-auto -rotate-12"
      />

      <img
        src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Kinder%2F4247134_2229438-06.png?alt=media&token=1edadcdd-9c5e-4c51-9916-7281649d3744"
        alt="pencil"
        className="absolute md:block  md:bottom-0 -bottom-[3%] left-0 md:w-[200px] w-[80px] h-auto rotate-45 "
      />
      <div className="md:w-2/3 w-full md:text-start text-center  md:mx-auto mt-6">
        <h5>{rpdata?.dbPrincipal?.name}</h5>
        <h4 className="color-2">{rpdata?.dbSlogan?.[2].slogan}</h4>
      </div>
      <div className="md:w-4/5 md:mx-auto w-full flex flex-col md:flex-row md:mb-0 mb-10">
        <div className="md:w-[60%]">
          {valuesData.map((items, index) => {
            return (
              <div
                key={index}
                className="bg-white w-4/5 mx-auto text-start flex md:flex-row flex-col my-6"
                data-aos="fade-right" data-aos-duration="1000"
              >
                <div className="flex flex-col justify-center items-center md:p-4 md:text-center text-center md:w-[30%]">
                  {items.icon}
                  <span className="capitalize">{items.title}</span>
                </div>
                <div className="p-4 border-l-[1px] border-gray-500 md:w-[70%]">
                  <p className="md:text-start">{items.description}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="md:w-[40%] w-full md:h-auto h-auto my-5 md:my-0 flex items-center">
          <div
            className="w-full md:h-[350px] h-[300px] bg-cover bg-center  rounded-md"
            style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Values_2;
